import { pathOr } from 'ramda'

import { usePublisherContext } from '@concepts/Publisher/store/context'
import Heading from '@atoms/UIHeading/Heading'
import { Hero } from 'src/generated/graphql'
import Container from '@atoms/UIContainer/Container'
import Button from '@atoms/UIButton/Button'
import bannerAnalytics from '@lib/gtm/events/banner'
import UseLink from '@atoms/UILink/UseLink'
import Contained from './Contained'
import Picture from '@atoms/UIPicture/Picture'
import segmentAnalytics from '@lib/segment/analytics'
import { Box, Flex, Text } from '@lib/UIComponents'

const useHeroBanner = (): Partial<Hero> =>
  pathOr({}, ['layout', 'hero'], usePublisherContext())

const styles = {
  container: {
    color: 'white',
    margin: [0, 'auto'],
    position: 'relative',
    display: 'flex',
    h1: {
      fontSize: [6, 7],
      lineHeight: '50px',
      maxWidth: '400px'
    },
    h4: {
      mt: 2,
      fontSize: [1, 2],
      opacity: '0.6'
    },
    button: {
      mt: 5,
      backgroundColor: 'white',
      borderColor: 'white',
      color: 'black',
      minWidth: '220px',
      justifyContent: 'center',
      fontSize: [1, 2],
      '&:hover': {
        backgroundColor: 'black',
        borderColor: 'black',
        color: 'white'
      }
    }
  },
  boxShadow: {
    pl: [4, 3, 8],
    margin: 'auto 0',
    alignSelf: 'flex-start',
    background:
      'radial-gradient(50% 50% at 50% 50%, rgba(0, 0, 0, 0.60) 0%, rgba(0, 0, 0, 0) 100%)'
  }
}

const getImageStyles = (hasTexts: boolean) => {
  const key = hasTexts ? 'withText' : 'withoutText'
  const imageStyles = {
    withText: {
      objectFit: 'cover',
      position: 'absolute',
      '@media screen and (min-width: 120em)': {
        left: '50%',
        transform: 'translateX(-50%)'
      }
    },
    withoutText: {
      objectFit: ['contain', 'cover'],
      position: 'relative'
    }
  }
  const containerStyles = {
    withText: {
      py: 6,
      minHeight: ['150px', '250px', '320px']
    },
    withoutText: {
      py: 0
    }
  }

  return {
    image: {
      width: '100%',
      maxWidth: '1920px',
      left: 0,
      top: 0,
      ...imageStyles[key]
    },
    container: {
      width: '100%',
      alignItems: 'center',
      flexDirection: 'column',
      position: 'relative',
      minHeight: '150px', // reduces CLS on mobile
      overflow: 'hidden',
      ...containerStyles[key]
    }
  }
}

const getHeroBannerSize = (size: string, hasTexts: boolean): object => {
  const height = ({
    height4 = '100%',
    height5 = '400px'
  }: {
    height4?: string
    height5?: string
  }): Array<string> => [
    hasTexts ? '100%' : 'auto',
    '100%',
    '100%',
    height4,
    height5
  ]

  const sizes = {
    default: {
      height: height({})
    },
    contained: {
      height: height({}),
      mx: 2,
      width: `calc( 100% - 1rem )`
    },
    big: {
      height: height({ height4: '537px', height5: '584px' }),
      maxWidth: 'auto'
    }
  } as Record<string, object>

  return sizes[size] || sizes.default
}

const HeroBanner = () => {
  const { image, imageMobile, headline, subHeadline, text, link, size } =
    useHeroBanner()

  const isContained = size === 'contained'
  const hasTexts = Boolean(headline || subHeadline || text)
  const sizeStyles = getHeroBannerSize(String(size), hasTexts)
  const imageStyles = getImageStyles(hasTexts)
  const imageUrl = String(image?.url)
  const altText = String(image?.altText || headline)
  const sourceTags = imageMobile?.url
    ? [
        { media: '(max-width: 414px)', srcSet: String(imageMobile.url) },
        { media: '(min-width: 415px)', srcSet: imageUrl }
      ]
    : []

  const applyAnalytics = () => {
    const currentUrl = window.location.href
    const destinationUrl = window.location.origin + link

    bannerAnalytics.homepageHero(currentUrl, destinationUrl)
    segmentAnalytics.trackHeroBanner(currentUrl, destinationUrl)
  }

  return (
    <UseLink
      useLink={Boolean(link)}
      to={String(link)}
      external
      onClick={applyAnalytics}
    >
      <Contained useContainer={isContained}>
        <Flex
          as="header"
          data-testid="hero-banner"
          sx={{ ...imageStyles.container, ...sizeStyles }}
        >
          <Picture
            src={imageUrl}
            alt={altText || "Today's Latest Sale"}
            sourceTags={sourceTags}
            sx={{ ...imageStyles.image, ...sizeStyles }}
          />

          {hasTexts && (
            <Container sx={styles.container}>
              <Box sx={styles.boxShadow}>
                {Boolean(headline) && <Heading as="h1">{headline}</Heading>}
                {Boolean(subHeadline) && <Text as="h4">{subHeadline}</Text>}

                {Boolean(text) && <Button type="button">{text}</Button>}
              </Box>
            </Container>
          )}
        </Flex>
      </Contained>
    </UseLink>
  )
}

export default HeroBanner
